<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-text-field
          v-model="form.nicename"
          label="Display Name"
          class="mt-5"
        />

        <translatable-textarea
          v-model="form.content"
          label="PDF Content"
        />

        <p>This supports custom formatting:</p>
        <ul>
          Variables & example:

          <li>[[voucher,code]]</li>
          <li>[[voucher,pruchased_at|date,format:Y/m/d]]</li>
          <li>[[voucher,expires_at|date,addWeekdays:5,format:Y/m/d]]</li>
          <li>[[owner,email]]</li>
          <li>etc...</li>
        </ul>
        <br>

        <br><br>

        <v-autocomplete
          v-model="form.merchant_id"
          placeholder="Merchant"
          :items="merchantOptions"
          item-text="title"
          item-value="value"
          outlined
          dense
        ></v-autocomplete>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableTextarea, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import merchantStore from '@/modules/merchant/store'
import store from '@/modules/product/store'
import { t } from '@/plugins/i18n'
import { required } from '@core/utils/validation'
import { computed, ref } from '@vue/composition-api'

export default {
  components: { TranslatableTextarea, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      nicename: props.resource?.nicename || '',
      content: useTranslatable(props.resource, 'content'),
      merchant_id: props.resource ? props.resource.merchant_id : null,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) {
        emit('submit', form.value)
      }
    }

    const merchantOptions = computed(() => merchantStore.state.all.map(merchant => ({ title: `#${merchant.id} - ${t(merchant.name)}`, value: merchant.id })))

    return {
      form,
      formElem,

      validate,
      required,

      merchantOptions,
      natureOptions: store.state.natureOptions,
    }
  },
}
</script>
